input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  /*  overflow: hidden;  remove this line*/

  /* New additions */
  height: 4px;
  background: #ccc;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #6b4fff;
  border-radius: 50%;
  border: none;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #6b4fff;
  border-radius: 50%;
  border: none;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}

/* Hover, active & focus Thumb: Webkit */

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px #6b4fff13
}

input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px #6b4fff33
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px #6b4fff33
}

/* Hover, active & focus Thumb: Firfox */
input[type="range"]::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px #6b4fff13
}

input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px #6b4fff33
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px #6b4fff33
}