@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";
@import "tailwindcss/variants";
@tailwind base;
@tailwind components;
@tailwind utilities
@tailwind variants

@layer base {
  :root {
    --color-primary: #6FAEF6;
    --color-secondary: #2889f7;
    --color-error: #B00020;
  }
}

@layer components {
  body {
    @apply bg-white
  }

  .prose {
    @apply max-w-max text-gray-900;
  }

  .error-input {
    input, select, textarea {
      @apply border-error-600 focus:ring-error-600 focus:border-error-600;
    }

    .error-input-element {
      @apply border-error-600 focus:ring-error-600 focus:border-error-600;
    }

    .error-message {
      display: block !important;
    }
  }

  //.ss-content {
  //  .ss-list {
  //    .ss-option {
  //      @apply bg-red
  //    }
  //  }
  //}
}