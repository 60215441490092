@import "./tailwind_layer";
@import "./slim_select_styles";
@import "./range_input";
@import "./accordion";

@font-face {
  font-family: 'Circular Std';
  src: url('fonts/CircularStd-Book.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Circular Std';
  src: url('fonts/CircularStd-Bold.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Circular Std';
  src: url('fonts/CircularStd-Black.otf') format('truetype');
  font-weight: 700;
}

body * {
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
  font-weight: 400;
}

.notyf__toast {
  border-radius: 16px !important;
  max-width: 552px !important;
}

.notyf__ripple {
  width: 768px !important;
}

select {
  cursor: pointer;
  background-image: url("images/icons/select_dropdown.svg") !important;
  background-position-y: 50% !important;
  background-position-x: 95% !important;
  background-repeat: no-repeat;

  &:not(:valid) {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  &:has(option:checked[value=""]) {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

[type='checkbox'], [type='radio'] {

  &:checked {
    border-color: transparent;
    background-color: currentcolor;
    background-size: 80% 100%;
    background-position: center;
    background-repeat: no-repeat;

    &:after {
      content: "";
      position: absolute;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      height: 9px;
      left: 8px;
      top: 7px;
      transform: rotate(45deg);
      width: 5px;
    }

  }

  &:disabled {
    background-color: rgb(137 141 163 / 20%);
  }
}

