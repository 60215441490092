.ss-option {
  color: black !important;
  background-color: transparent !important;

  &:hover {
    background-color: #80808017 !important;
    color: black !important;
  }
}

.ss-selected {
  background-color: #80808017 !important;
  border-radius: 3px;
  font-weight: 600;
}

.ss-main {
  border-color: #d4d4d8;

  &:focus {
    box-shadow: none !important;
  }
}

.ss-content {
  background-color: white;
}

.ss-content .ss-search input {
  &:focus {
    box-shadow: none !important;
  }
}